import { graphql } from "gatsby";
import PropTypes from "prop-types";

import PublicationCardComponent from "../../components/PublicationCard";

const PublicationCard = ({ data, cta, className }) => PublicationCardComponent(data, cta, className);

export const query = graphql`
  fragment PublicationCard on SanityPublication {
    id
    isbn
    publisher
    publishing_house
    scope
    slug {
      current
    }
    images {
      ...ImageWithPreview
      alt
    }
    title
    year
    authors
  }
`;

PublicationCard.propTypes = {
  className: PropTypes.string,
  cta: PropTypes.node,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

PublicationCard.defaultProps = {
  className: "",
  cta: null,
};

export default PublicationCard;
