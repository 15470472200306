import React, { useEffect, useState } from "react";

import { useLocation } from "@reach/router";
import BlockContent from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import SanityImage from "gatsby-plugin-sanity-image";
import scrollTo from "gatsby-plugin-smoothscroll";
import PropTypes from "prop-types";

import Button from "../../components/Button/Button";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import HorizontalSlider from "../../components/HorizontalSlider/HorizontalSlider";
import ImageAccordion from "../../components/ImageAccordion/ImageAccordion";
import ImageTextGrid from "../../components/ImageTextGrid/ImageTextGrid";
import Person from "../../components/Person/Person";
import Section from "../../components/Section/Section";
import TextHeader from "../../components/TextHeader/TextHeader";
import Arrival from "../Arrival/Arrival";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import ImageSlider from "../ImageSlider/ImageSlider";
import MainLayout from "../layouts/MainLayout";
import PublicationCard from "../Publication/PublicationCard";
import QuickInfos from "../QuickInfos/QuickInfos";
import Seo from "../Seo/Seo";
import GetCallToActionContext from "../utils/GetCallToActionContext";

const MuseumPage = ({ data: { page } }) => {
  const location = useLocation();
  const [currentAnchor, setCurrentAnchor] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (location.hash) {
      scrollTo(location.hash);

      // TODO: not loaded images result in wrong scroll position.
      if (currentAnchor !== location.hash) {
        setTimeout(() => {
          scrollTo(location.hash);
        }, 500);
        setCurrentAnchor(location.hash);
      }
    }
  }, []);

  return (
    <MainLayout breadcrumb={<Breadcrumb id={page.id} />}>
      <Section>
        <ContentContainer>
          <h1>{page.title}</h1>
        </ContentContainer>
        <div className="-mt-8 lg:-mt-18">
          <ImageSlider data={page.slider} />
        </div>
      </Section>
      <Section idName="events">
        <QuickInfos data={page} />
      </Section>
      {page.publications?.length > 0 && (
        <Section idName="publications">
          <TextHeader title={t("publications")} />
          <HorizontalSlider slidesPerView={4}>
            {page.publications?.map(
              (publication) => (
                <PublicationCard data={publication} />
              ),
            )}
          </HorizontalSlider>
        </Section>
      )}
      <Section idName="history">
        <ImageTextGrid headline={t("history")}>
          {page.history?.elements?.map(
            (element, index) => {
              if (element.image) {
                return (
                  element.image?.asset && (
                    <div className={index > 0 ? "image" : "image"} key={element._key}>
                      <SanityImage
                        {...element.image}
                        width={800}
                      />
                      <div className="">{element.image.alt}</div>
                    </div>
                  )
                );
              }

              if (element.text) {
                return (
                  <>
                    <h3>{element.headline}</h3>
                    {element.text}
                    {GetCallToActionContext(element.callToAction).path !== "" && (
                      <div className="mt-10">
                        <Button
                          variant="secondary"
                          color="black"
                          title={element.callToAction?.title}
                          size="large"
                          href={GetCallToActionContext(element.callToAction).path}
                        >
                          {element.callToAction?.title}
                        </Button>
                      </div>
                    )}
                  </>
                );
              }

              return null;
            },
          )}
        </ImageTextGrid>
      </Section>
      <Section idName="RuhrKunstMuseen">
        {page._rawRuhrKunstMuseenText && (
          <ContentContainer>
            <h2 className="h1">{t("RuhrKunstMuseen")}</h2>
            <BlockContent
              blocks={page._rawRuhrKunstMuseenText}
            />
          </ContentContainer>
        )}
        {page.ruhrKunstMuseen?.elements.length > 0 && (
          <ContentContainer>
            <ContentContainer>
              <h2 className="h1">{t("RuhrKunstMuseen")}</h2>
            </ContentContainer>
            <ImageTextGrid className="-mt-36">
              {page.ruhrKunstMuseen?.elements?.map(
                (element) => {
                  if (element.image) {
                    return (
                      element.image?.asset && (
                        <SanityImage
                          {...element.image}
                          width={800}
                        />
                      )
                    );
                  }

                  if (element.text) {
                    return (
                      <>
                        <h3>{element.headline}</h3>
                        {element.text}
                        {GetCallToActionContext(element.callToAction).path !== "" && (
                          <div className="mt-10">
                            <Button
                              variant="secondary"
                              color="black"
                              title={element.callToAction?.title}
                              size="large"
                              href={GetCallToActionContext(element.callToAction).path}
                            >
                              {element.callToAction?.title}
                            </Button>
                          </div>
                        )}
                      </>
                    );
                  }

                  return null;
                },
              )}
            </ImageTextGrid>
          </ContentContainer>
        )}
      </Section>
      <Section idName="kunstImOeffentlichenRaum">
        {page._rawKunstImOeffentlichenRaumText && (
          <ContentContainer>
            <h2 className="h1">{t("artInPublicSpaces")}</h2>
            <BlockContent
              blocks={page._rawKunstImOeffentlichenRaumText}
            />
          </ContentContainer>
        )}
        {page.kunstImOeffentlichenRaum?.elements.length > 0 && (
          <ContentContainer>
            <ContentContainer>
              <h2 className="h1">{t("artInPublicSpaces")}</h2>
            </ContentContainer>
            <ImageTextGrid className="-mt-36">
              {page.kunstImOeffentlichenRaum?.elements?.map(
                (element, index) => {
                  if (element.image) {
                    return (
                      element.image?.asset && (
                        <div className={index > 0 ? "image" : "image"} key={element._key}>
                          <SanityImage
                            {...element.image}
                            width={800}
                          />
                          <div className="">{element.image.alt}</div>
                        </div>
                      )
                    );
                  }

                  if (element.text) {
                    return (
                      <>
                        <h3>{element.headline}</h3>
                        {element.text}
                        {GetCallToActionContext(element.callToAction).path !== "" && (
                          <div className="mt-10">
                            <Button
                              variant="secondary"
                              color="black"
                              title={element.callToAction?.title}
                              size="large"
                              href={GetCallToActionContext(element.callToAction).path}
                            >
                              {element.callToAction?.title}
                            </Button>
                          </div>
                        )}
                      </>
                    );
                  }

                  return null;
                },
              )}
            </ImageTextGrid>
          </ContentContainer>
        )}
      </Section>
      <Section idName="team">
        <ContentContainer>
          <h1>Team</h1>
        </ContentContainer>
        <div className="grid grid-cols-1 mx-18 mt-18 lg:mt-0 lg:mx-0 lg:grid-cols-3">
          {page.team?.elements?.map(
            (element, index) => (
              element.image && (
                <Person
                  linkEmail
                  email={element.email}
                  image={(
                    element.image?.asset && element.image?.alt && (
                      <SanityImage
                        {...element.image}
                        width={800}
                      />
                    )
                  )}
                  name={element.name}
                  position={element.position}
                  tel={element.phonenumber}
                />
              )
            ),
          )}
        </div>
      </Section>
      <Section idName="infos">
        <Arrival data={page} />
      </Section>
    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: sanityMuseum(id: {eq: $id}) {
      i18n_lang
      id
      title
      slider {
        ...ImageSlider
      }
      _rawQuickInfoText
      _rawRoomsText
      _rawTeamText
      _rawAboutUs
      _rawKunstImOeffentlichenRaumText
      _rawRuhrKunstMuseenText
      publications {
        ...PublicationCard
      }
      history {
        elements {
          headline
          text
          callToAction {
            linkType
            blank
            title
            href
            _rawInternalLink(resolveReferences: {maxDepth: 1})
          }
          image {
            ...ImageWithPreview
            alt
          }
        }
      }
      ruhrKunstMuseen {
        elements {
          headline
          text
          callToAction {
            linkType
            blank
            title
            href
            _rawInternalLink(resolveReferences: {maxDepth: 1})
          }
          image {
            ...ImageWithPreview
            alt
          }
        }
      }
      kunstImOeffentlichenRaum {
        elements {
          headline
          callToAction {
            linkType
            blank
            title
            href
            _rawInternalLink(resolveReferences: {maxDepth: 1})
          }
          text
          image {
            ...ImageWithPreview
            alt
          }
        }
      }
      rooms {
        elements {
          headline
          text
          image {
            ...ImageWithPreview
            alt
          }
        }
      }
      team {
        elements {
          image {
            ...ImageWithPreview
            alt
          }
          email
          name
          phonenumber
          position
        }
      }
      Metadata {
        ...MetaData
      }
    }
  }
`;

MuseumPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default MuseumPage;
