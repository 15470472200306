import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { Keyboard, Mousewheel, Navigation } from "swiper";
// eslint-disable-next-line import/no-unresolved
import "swiper/css/bundle";
import { Swiper, SwiperSlide } from "swiper/react";

import * as styles from "./HorizontalSlider.module.scss";

const HorizontalSlider = ({
  children, description, headline, slidesPerView, className,
}) => {
  const slideClasses = {
    0: "",
    1: styles.swiperSlideSecond,
    2: styles.swiperSlideThird,
  };

  return (
    <div className={classNames("mx-18", className)}>
      {headline && (
        <div className="mb-60 lg:mb-90 lg:grid lg:grid-cols-12 lg:gap-x-60">
          <h1 className="text-h1 mb-18 lg:mb-42 lg:col-start-2 lg:col-span-10">{headline}</h1>
          <div className="text-lead lg:col-start-5 lg:col-span-7">{description}</div>
        </div>
      )}
      <div>
        <Swiper
          className={classNames(styles.swiper, className)}
          breakpoints={{
            0: {
              slidesPerView: "auto",
              spaceBetween: 30,
            },
            1024: {
              slidesPerView,
              spaceBetween: 60,
            },
          }}
          onAfterInit={(swiper) => {
            swiper.wrapperEl.classList.add(styles.swiperWrapper);
            swiper.navigation.prevEl.classList.add(styles.swiperButtonPrev);
            swiper.navigation.nextEl.classList.add(styles.swiperButtonNext);
          }}
          navigation
          keyboard
          mousewheel={{
            forceToAxis: true,
            thresholdTime: 1000,
          }}
          modules={[Navigation, Mousewheel, Keyboard]}
        >
          {React.Children.map(children, (child, index) => (
            <SwiperSlide className={slideClasses[index % 3]}>
              {child}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

HorizontalSlider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
  headline: PropTypes.string,
  slidesPerView: PropTypes.number,
};

HorizontalSlider.defaultProps = {
  className: "",
  description: "",
  headline: "",
  slidesPerView: 3,
};

export default HorizontalSlider;
