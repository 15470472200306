import React from "react";

import SanityImage from "gatsby-plugin-sanity-image";
import PropTypes from "prop-types";

import GetInternalLinkContext from "../templates/utils/GetInternalLinkContext";
import { useTranslation } from "gatsby-plugin-react-i18next";

const PublicationCard = (artwork, cta, className) => {
  const {
    id,
    slug,
    images,
    publishing_house,
    title,
    year,
  } = artwork;

  const { t } = useTranslation();
  const link = GetInternalLinkContext({ id, slug: `/${t("publikation")}/${slug?.current?.replace(";", "-")}` });

  return (
    <div>
      <a href={link.path} title={link.title} className={className}>
        {images?.length > 0 && images[0]?.asset && (
          <SanityImage
            {...images[0]}
            width={330}
            className="w-full"
            options={{ __experimentalAspectRatio: true }}
          />
        )}
        <div className="text-body2 mb-3 mt-18 break-words">{publishing_house}</div>
        <h5 className="break-words">{title}{year ? `, ${year}` : "" }</h5>
      </a>
      {cta}
    </div>
  );
};

PublicationCard.propTypes = {
  artwork: PropTypes.oneOf([PropTypes.object]).isRequired,
  className: PropTypes.string,
  cta: PropTypes.node,
};

PublicationCard.defaultProps = {
  className: "",
  cta: null,
};

export default PublicationCard;
