import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import useMediaQuery from "../../hooks/useMediaQuery";

const Person = (props) => {
  const {
    contentBottom,
    email,
    image,
    name,
    position,
    tel,
    linkTel,
    linkEmail,
  } = props;

  const isMobile = useMediaQuery("(max-width: 1024px)");

  const telLink = linkTel ? `<a href="tel:${tel}">${tel}</a>` : tel;
  const emailLink = linkEmail ? `<a class="person-mail" href="mailto:${email}">${email}</a>` : email;
  const telText = isMobile ? tel : `Telefon: ${telLink}`;
  const emailText = isMobile ? email : `E-Mail: ${emailLink}`;

  return (
    <div className="grid gap-y-30">
      <div className={classNames({ "order-last": !contentBottom })}>{image}</div>
      <div className="grid gap-y-9">
        <div className="grid gap-y-3">
          <div className="text-body1">{position}</div>
          <div className="text-h4-mobile lg:text-h4-desktop">{name}</div>
        </div>
        <div className="grid gap-y-3">
          <div className="text-mobile-body1 lg:text-body2-desktop">{telText}</div>
          <div className="text-mobile-body1 lg:text-body2-desktop" dangerouslySetInnerHTML={{ __html: emailText }} />
        </div>
      </div>
    </div>
  );
};

Person.propTypes = {
  contentBottom: PropTypes.bool,
  email: PropTypes.string.isRequired,
  image: PropTypes.node.isRequired,
  linkEmail: PropTypes.bool,
  linkTel: PropTypes.bool,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  tel: PropTypes.string.isRequired,
};

Person.defaultProps = {
  contentBottom: false,
  linkEmail: false,
  linkTel: false,
};

export default Person;
